<template>
  <div>
    <div v-show="clientDiv">
      <div class="table_common">
        <div class="search_flex_box">
          <div class="search_condition">
            <el-form ref="form" :model="clientSearchform">
              <el-form-item label="客户编号:">
                <el-input v-model="clientSearchform.coreEnterpriseId" @input="searchInput1" maxlength="20"></el-input>
              </el-form-item>
              <el-form-item label="客户名称:">
                <el-input v-model="clientSearchform.purchaseName"></el-input>
              </el-form-item>
              <el-form-item label="合作年限:">
                <el-select v-model="clientSearchform.age" placeholder="" class="select_class">
                  <!-- <el-option v-for="item in cooperYearsOptions" :key="item.value" :label="item.label" :value="item.value"></el-option> -->
                  <el-option v-for="item in cooperYearsOptions" :key="item.codeState" :label="item.value"
                    :value="item.codeState"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div class="search_boxs flex_center_between_box">
            <el-tooltip popper-class="search" effect="light" content="搜索" placement="top">
              <div class="search_button_box" @click="searchForm()">
                <i class="iconfont icon-10sousuo"></i>
              </div>
            </el-tooltip>
            <el-tooltip popper-class="search" effect="light" content="重置" placement="top">
              <div class="reset_button_box" @click="clearCliSearch()">
                <i class="iconfont icon-shuaxin"></i>
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="table_box">
          <div class="table_box_top flex_center">
            <div class="table_box_top_title">我的客户</div>
            <div class="flex_center_end_box">
              <div class="search_button button" @click="addClientDialog(); dialogClientTitle = '添加客户'">添加客户</div>
              <div class="search_button button" @click="addInvoice(); dialogClientTitle = '添加发票信息'">添加发票</div>
              <div class="search_button button data_export">数据导出</div>
            </div>
          </div>
          <div class="table_box_bottom">
            <el-table ref="treeTable" :data="clientTable" stripe border style="width: 100%" @expand-change="onExpandChange">
              <el-table-column type="expand" align="center" width="1" class-name="border_no">
                <template slot-scope="scope">
                  <div class="tree_table_box">
                    <div class="search_condition sub_search_condition flex_center_between_box">
                      <el-form ref="invSearchForm" :model="invSearchForm">
                        <el-form-item label="发票号:" class="financing_border" prop="invoiceNo">
                          <el-input v-model="invSearchForm.invoiceNo" @input="searchNum" maxlength="20"></el-input>
                        </el-form-item>
                        <el-form-item label="发票日期:" prop="invoiceDate">
                          <el-date-picker v-model="invSearchForm.invoiceDate" :picker-options="applyDateChoose"
                            value-format="yyyy-MM-dd" prefix-icon="iconfont icon-riqi" type="daterange"
                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :editable="false">
                          </el-date-picker>
                        </el-form-item>
                        <el-form-item label="发票金额:" prop="invoiceAmount">
                          <el-input v-model="invSearchForm.invoiceAmount" @input="searchMoney" maxlength="8"></el-input>
                        </el-form-item>
                      </el-form>
                      <div class="search_boxs flex_center_between_box">
                        <el-tooltip popper-class="search" effect="light" content="搜索" placement="top">
                          <div class="search_button_box" @click="getInvList()">
                            <i class="iconfont icon-10sousuo"></i>
                          </div>
                        </el-tooltip>
                        <el-tooltip popper-class="search" effect="light" content="重置" placement="top">
                          <div class="reset_button_box" @click="clearInvSearch()">
                            <i class="iconfont icon-shuaxin"></i>
                          </div>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                  <div class="tree_table_box">
                    <el-table :data="childTable" stripe border style="width: 100%" :key="scope.row.id">
                      <el-table-column label="发票号" prop="invoiceNo" align="center" :width="width175" show-overflow-tooltip
                        :resizable="false" />
                      <el-table-column label="发票日期" prop="invoiceDate" align="center" sortable show-overflow-tooltip
                        :resizable="false" />
                      <el-table-column label="发票金额(元)" prop="invoiceAmount" align="right" sortable header-align="center"
                        class-name="align_table" show-overflow-tooltip :resizable="false">
                        <template slot-scope="scope">
                          {{ scope.row.invoiceAmount | NumFormat }}
                        </template>
                      </el-table-column>
                      <el-table-column label="发票确认状态" prop="isConfirm" align="center" show-overflow-tooltip
                        :resizable="false">
                        <template scope="scope">
                          {{ scope.row.isConfirm == 0 ? '不认可' : '认可' }}
                        </template>
                      </el-table-column>
                      <el-table-column label="发票评级" prop="invoiceGrade" align="center" show-overflow-tooltip
                        :resizable="false">
                        <template slot-scope="scope">
                          <div class="link" @click="clickInvoiceGrade(scope.row.invoiceGrade)">{{ scope.row.invoiceGrade }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column label="发票账期(天)" prop="accPeriod" align="center" sortable show-overflow-tooltip
                        :resizable="false" />
                      <el-table-column label="账款到期日" prop="invoiceDateEnd" align="center" sortable show-overflow-tooltip
                        :resizable="false" />
                      <el-table-column label="操 作" prop="operation" align="center" fixed="right" :resizable="false">
                        <template slot-scope="scope">
                          <div class="table_oper flex_center">
                            <el-tooltip class="item_color" effect="light" content="编辑" placement="top"
                              v-if="scope.row.invoiceState == '0'">
                              <i class="iconfont icon-bianji" @click="editInvDetail(scope.row)"></i>
                            </el-tooltip>
                            <el-tooltip class="item_color" effect="light" content="详情" placement="top" v-else>
                              <i class="iconfont icon-shuxingliebiaoxiangqing" @click="dialogInvDetail(scope.row)"></i>
                            </el-tooltip>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                    <Page :totalFont="true" :total="childTotalPage" :currentPage='invSearchForm.pageNo'
                      @onPageChange="onChildPageChange" :key='refPage'></Page>
                  </div>
                </template>
              </el-table-column>
              <!-- <template slot="empty">
                                <NoData />
                            </template> -->
              <el-table-column label="客户编号" prop="coreEnterpriseId" align="center" show-overflow-tooltip
                :resizable="false" :width="width175" class-name="financing_cursor">
                <template slot-scope="scope">
                  <span class="expans_icon" :class="{ expandIconExpanded: scope.row.isExpand }"
                    @click="onClickExpansion(scope.row)">
                    <i class="iconfont icon-youjiantou"></i>
                    {{ scope.row.coreEnterpriseId }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="客户名称" prop="coreEnterpriseName" align="left" header-align="center"
                show-overflow-tooltip :resizable="false" :width="comapnyWidth" class-name="financing_cursor">
                <template slot-scope="scope">
                  <div class="link" @click="detailIndex(scope.row)">{{ scope.row.coreEnterpriseName }}</div>
                </template>
              </el-table-column>
              <el-table-column label="客户发票数量" prop="invoiceQty" align="right" header-align="center" border sortable
                show-overflow-tooltip :resizable="false" :width="width148">
                <template slot-scope="scope">
                  <div>{{ scope.row.invoiceQty ? scope.row.invoiceQty : 0 }}</div>
                </template>
              </el-table-column>
              <el-table-column label="客户联系人" prop="contacts" align="center" :resizable="false" show-overflow-tooltip
                class-name="align_table" :width="width138" />
              <el-table-column label="客户电话" prop="phone" align="center" header-align="center" :resizable="false"
                show-overflow-tooltip class-name="align_table" :width="width138" />
              <el-table-column label="客户邮箱" prop="email" align="center" show-overflow-tooltip :resizable="false"
                :width="width138">
                <template slot-scope="scope">
                  <div class="noLink">{{ scope.row.email }}</div>
                </template>
              </el-table-column>
              <el-table-column label="合作年限" prop="age" align="center" show-overflow-tooltip :resizable="false"
                :width="width120">
                <template slot-scope="scope">
                  <div v-if="scope.row.age == '0'">新客户</div>
                  <div v-else-if="scope.row.age == '1'">1-3年</div>
                  <div v-else>3年以上</div>
                </template>
              </el-table-column>
              <el-table-column label="合作描述" prop="suppDesc" align="center" show-overflow-tooltip :resizable="false">
                <template slot-scope="scope">
                  <div v-if="scope.row.suppDesc">
                    {{ scope.row.suppDesc }}
                  </div>
                  <div v-else>暂无描述</div>
                </template>
              </el-table-column>
              <el-table-column label="操 作" prop="operation" align="center" :resizable="false" fixed="right"
                :width="lastWidth">
                <template slot-scope="scope">
                  <div class="table_oper flex_center">
                    <el-tooltip class="item_color" effect="light" content="编辑" placement="top">
                      <i class="iconfont icon-bianji" @click="editClientDialog(scope.row); dialogClientTitle = '编辑客户'"></i>
                    </el-tooltip>
                    <!-- <el-tooltip class="item_color" effect="light" content="详情" placement="top">
                                            <i class="iconfont icon-shuxingliebiaoxiangqing" @click="detailClientDialog(scope.row);dialogClientTitle='客户详情'"></i>
                                        </el-tooltip> -->
                    <el-tooltip class="item_color" effect="light" content="服务" placement="top">
                      <i class="iconfont icon--fuwu-xianxing"></i>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <Page :totalFont=true :total="faTotalPage" :currentPage='clientSearchform.pageNo' @onPageChange="onPageChange">
          </Page>
        </div>
      </div>
    </div>
    <!-- 发票评级弹窗 -->
    <el-dialog title="发票评级" :visible.sync="invoiceGradeDialog" center class="dialog_1268" :close-on-click-modal="false">
      <img :src="invoiceAImg" v-if="invoiceImg == 1">
      <img :src="invoiceBImg" v-else-if="invoiceImg == 2">
      <img :src="invoiceCImg" v-else-if="invoiceImg == 3">
      <img :src="invoiceDImg" v-else-if="invoiceImg == 4">
      <img :src="invoiceNaImg" v-else>
    </el-dialog>
    <!-- 添加,编辑客户弹框 -->
    <el-dialog :title="dialogClientTitle" :visible.sync="showClientPop" center class="add_client_dialog change_padding"
      :class="disabledClient ? 'no_star' : ''" :close-on-click-modal="false" :before-close="cancelAddClientForm">
      <div class="add_invoice_messages inner_common">
        <el-form :model="addClientform" ref="addClientform" :rules="addClientRule"
          :hide-required-asterisk="disabledClient">
          <!-- <el-form-item label="客户编号:" prop="coreEnterpriseId">
                        <el-input v-model="addClientform.coreEnterpriseId" disabled maxlength="20"></el-input>
                    </el-form-item> -->
          <el-form-item label="客户名称:" prop="coreEnterpriseName">
            <el-autocomplete class="inline-input" placeholder="请输入公司名称" v-model="addClientform.coreEnterpriseName"
              :fetch-suggestions="querySearch" :trigger-on-focus="false" @change="changeClient" @select="handleSelect">
            </el-autocomplete>
          </el-form-item>
          <!-- <el-form-item label="国家地区:" prop="country">
            <el-select v-model="addClientform.country" @change="changeAddress0">
              <el-option v-for="item in  addressList0" :key="item.index" :label="item.name" :value="item.name" />
            </el-select>
          </el-form-item>
          <el-form-item label="所属省市:" prop="city" class="flex">
            <el-form-item prop="province" class="two_select">
              <el-select v-model="addClientform.province" @change="changeAddress1">
                <el-option v-for="item in  addressList1" :key="item.index" :label="item.name" :value="item.name" />
              </el-select>
            </el-form-item>
            <el-form-item prop="city" class="two_select">
              <el-select v-model="addClientform.city" @change="changeAddress2">
                <el-option v-for="item in  addressList2" :key="item.index" :label="item.name" :value="item.name" />
              </el-select>
            </el-form-item>
          </el-form-item> -->
          <el-form-item label="客户联系人:" prop="contacts">
            <el-input v-model="addClientform.contacts" :disabled='disabledClient' maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="客户电话:" prop="phone">
            <el-input v-model="addClientform.phone" :disabled='disabledClient' maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="客户邮箱:" prop="email">
            <el-input v-model="addClientform.email" :disabled='disabledClient' maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="合作年限:" prop="age">
            <el-select v-model="addClientform.age" placeholder="" class="select_class" :disabled='disabledClient'>
              <el-option v-for="item in cooperYearsOptions" :key="item.codeState" :label="item.value"
                :value="item.codeState"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="合作信息描述:" prop="suppDesc">
            <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" maxlength="200" show-word-limit
              v-model="addClientform.suppDesc" :disabled="disabledClient">
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <div v-if="!disabledClient">
          <el-button class="footer_button2" round @click="cancelAddClientForm()">取 消</el-button>
          <el-button class="footer_button1" type="primary" @click="submitClientForm('addClientform')">提 交</el-button>
        </div>
        <div v-else>
          <el-button class="footer_button1" type="primary" @click="showClientPop = false">关 闭</el-button>
        </div>
      </span>
    </el-dialog>

    <!-- 添加发票弹框 -->
    <el-dialog title="添加发票" :visible.sync="addInvoiceDialog" center class="add" :close-on-click-modal="false">
      <div class="add_invoice_content">
        <div class="search_invoice">
          <el-input v-model="addInvSearch.clientName" placeholder="输入客户名称搜索">
            <i slot="suffix" class="el-input__icon iconfont icon-10sousuo" @click="getAddClientList()"></i>
          </el-input>
        </div>
        <div class="choose_invoice">
          <div class="choose_invoice_title">我的客户:</div>
          <div class="choose_invoice_list">
            <ul>
              <el-tooltip :content="item.coreEnterpriseName" v-for="(item, index) in purchaserList" :key="index"
                placement="top" effect="light" popper-class="choose_tooltip">
                <li class="choose_invoice_button over" @click="changeStyle(index, item)" ref="choose"
                  :class="state == index ? 'choose_active_change' : 'choose_active'">
                  {{ item.coreEnterpriseName }}
                </li>
              </el-tooltip>
            </ul>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" class="footer_button1" @click="next()">下一步</el-button>
      </span>
    </el-dialog>
    <!-- 添加发票信息弹框 -->
    <el-dialog :title="addInvoiceMessageTitle" :visible.sync="addInvMessageDialog" center
      class="add change_padding inner_common_dialog" :close-on-click-modal="false">
      <div class="add_invoice_messages inner_common">
        <el-form :model="form" ref="form" :rules="rulesInvoice" :key="refresh">
          <el-form-item label="客户名称:" prop="purchaseName">
            <el-input v-model="form.purchaseName" disabled maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="发票号码:" prop="invoiceNo">
            <el-input v-model="form.invoiceNo" maxlength="8"
              onkeyup='this.value=this.value.replace(/\D/gi,"")'></el-input>
          </el-form-item>
          <el-form-item label="发票金额:" prop="newInvoiceAmount">
            <el-input v-model="form.newInvoiceAmount" type="text" onkeyup='this.value=this.value.replace(/\D/gi,"")'
              @focus="inputMoneyFocus(form.newInvoiceAmount)" @blur="inputMoneyBlur(form.newInvoiceAmount)"
              maxlength="20"></el-input>
            <el-input v-model="form.invoiceAmount" type="text" class="hInpt"></el-input>
          </el-form-item>
          <el-form-item label="发票日期:" prop="invoiceDate">
            <el-date-picker v-model="form.invoiceDate" :editable='false' prefix-icon="iconfont icon-riqi" type="date"
              value-format="yyyy-MM-dd" :picker-options="applyDateChoose">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="发票账期:" prop="invoiceAccountDate">
            <el-input v-model="form.invoiceAccountDate" type="text" onkeyup='this.value=this.value.replace(/\D/gi,"")'
              maxlength="3"></el-input>
          </el-form-item>
          <el-form-item label="发票付款状态:" prop="invoiceState">
            <el-select v-model="form.invoiceState" placeholder="" @change="changeInvState()">
              <el-option v-for="item in isConfirmOptions" :key="item.codeState" :label="item.value"
                :value="item.codeState"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="付款金额:" prop="newPaymentAmount" v-if="addPayState">
            <el-input v-model="form.newPaymentAmount" maxlength="12" onkeyup='this.value=this.value.replace(/\D/gi,"")'
              @focus="inputPayFocus(form.newPaymentAmount)" @blur="inputPayBlur(form.newPaymentAmount)"></el-input>
          </el-form-item>
          <el-form-item label="付款金额:" v-else>
            <el-input v-model="form.newPaymentAmount" maxlength="12" :disabled='true'
              onkeyup='this.value=this.value.replace(/\D/gi,"")' @focus="inputPayFocus(form.newPaymentAmount)"
              @blur="inputPayBlur(form.newPaymentAmount)"></el-input>
          </el-form-item>
          <el-form-item label="发票付款日期:" prop="paymentDate" v-if="addPayState">
            <el-date-picker v-model="form.paymentDate" :editable='false' prefix-icon="iconfont icon-riqi" type="date"
              value-format="yyyy-MM-dd" :picker-options="applyDateChoose">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="发票付款日期:" v-else>
            <el-date-picker v-model="form.paymentDate" :editable='false' :disabled='true' prefix-icon="iconfont icon-riqi"
              type="date" value-format="yyyy-MM-dd" :picker-options="applyDateChoose">
            </el-date-picker>
          </el-form-item>

          <el-form-item label="上传付款凭证:" class="change_flex" prop="paymentVoucher" v-if="addPayState">
            <el-upload ref="uploadPayForm" :http-request="payUploadFile" :on-remove="payFileRemove" action="fakeaction"
              v-model="form.paymentVoucher" class="upload_demo" :file-list="payFileList" list-type="picture"
              accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PBG" :limit='1'>
              <div class="upload_box">
                <i class="el-icon-plus"></i>
              </div>
              <span slot="tip" class="el-upload__tip">支持JPG、PNG格式，大小不超过15M</span>
            </el-upload>
          </el-form-item>
          <el-form-item label="上传付款凭证:" class="change_flex" v-else>
          </el-form-item>
          <el-form-item label="买家是否已收货:" class="change_width" prop="isReceived">
            <el-radio-group v-model="form.isReceived">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="买家是否已收发票:" class="change_width" prop="isInvoice">
            <el-radio-group v-model="form.isInvoice">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="上传发票:" class="change_flex" prop="invoiceUrl">
            <el-upload ref="uploadForm" :http-request="handleUploadFile" :on-remove="handleRemove" action="fakeaction"
              v-model="form.invoiceUrl" class="upload_demo" :file-list="fileList" list-type="picture"
              accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PBG" :limit='1'>
              <div class="upload_box">
                <i class="el-icon-plus"></i>
              </div>
              <span slot="tip" class="el-upload__tip">支持JPG、PNG格式，大小不超过15M</span>
              <!-- <div v-if="ulDisplay">
                                <ul>
                                    <li v-for="(item,index) in fileList" :key="index" class="flex_center">
                                        <img :src="compressImg">
                                    </li>
                                </ul>
                            </div> -->
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button2" round @click="cancel()">取 消</el-button>
        <el-button class="footer_button1" type="primary" @click="submitNewInvForm('form')">提 交</el-button>
      </span>
    </el-dialog>

    <!-- 发票确认弹框 -->
    <el-dialog :title="dialogInvTit" :visible.sync="applyInvDialog" center class="dialog_width500 dialog_add_edit_form"
      :close-on-click-modal="false" :before-close="cancleApplyInvDialog">
      <div class="add_invoice_message inner_common">
        <el-form :model="invPartner" ref="invPartner" :rules="invRules" :key="refreshTwo">
          <el-form-item label="发票号码:" prop="invoiceNo">
            <el-input v-model="invPartner.invoiceNo" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="发票金额:" prop="invoiceAmount">
            <el-input v-model="invPartner.invoiceAmount" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="发票日期:" prop="invoiceDate">
            <el-input v-model="invPartner.invoiceDate" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="发票账期:" prop="invoiceAccountDate">
            <el-input v-model="invPartner.invoiceAccountDate" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="发票付款状态:" prop="invoiceState" v-if="disableInvInpt">
            <el-select v-model="invPartner.invoiceState" placeholder="" @change="changeEditInvState()">
              <el-option v-for="item in isConfirmOptions" :key="item.codeState" :label="item.value"
                :value="item.codeState"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发票付款状态:" v-else>
            <el-input v-model="invPartner.invoiceStateName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="付款金额:" prop="paymentAmount" v-if="!editPayState">
            <el-input v-model="invPartner.paymentAmount" maxlength="8" @input="regNumber1"
              @focus="inputEditPayFocus(invPartner.paymentAmount)" @blur="inputEditPayBlur(invPartner.paymentAmount)">
            </el-input>
          </el-form-item>
          <el-form-item label="付款金额:" v-else>
            <el-input v-model="invPartner.paymentAmount" @input="regNumber1" :disabled="true" maxlength="8"></el-input>
          </el-form-item>
          <el-form-item label="发票付款日期:" prop="paymentDate" v-if="!editPayState">
            <el-date-picker v-model="invPartner.paymentDate" :editable='false' prefix-icon="iconfont icon-riqi"
              type="date" value-format="yyyy-MM-dd" :picker-options="applyDateChoose">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="发票付款日期:" v-else>
            <el-input v-model="invPartner.paymentDate" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="上传付款凭证:" class="change_flex" prop="paymentVoucher" v-if="!editPayState">
            <el-upload ref="uploadPayForm" :http-request="payEditUploadFile" :on-remove="payEditFileRemove"
              action="fakeaction" v-model="invPartner.paymentVoucher" class="upload_demo" :file-list="payEditUrlList"
              list-type="picture" accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PBG">
              <div class="upload_box">
                <i class="el-icon-plus"></i>
              </div>
              <span slot="tip" class="el-upload__tip">支持JPG、PNG格式，大小不超过15M</span>
            </el-upload>
          </el-form-item>
          <el-form-item label="发票付款凭证:" v-else>
            <ul class="invimg_list flex_wrap">
              <li v-for="(item, index) in payUrlList" :key="index" class="flex_center">
                <img :src="jpg + item.imgUrl" />
              </li>
            </ul>
          </el-form-item>
          <el-form-item label="买家是否已收货:" class="change_width" prop="isReceived">
            {{ invPartner.isReceived == '1' ? '是' : '否' }}
          </el-form-item>
          <el-form-item label="买家是否已收发票:" class="change_width" prop="isInvoice">
            {{ invPartner.isInvoice == '1' ? '是' : '否' }}
          </el-form-item>
          <el-form-item label="发票图片:" prop="invoiceUrl">
            <ul class="invimg_list flex_wrap">
              <li v-for="(item, index) in invoiceUrlList" :key="index" class="flex_center">
                <img :src="jpg + item.imgUrl" />
              </li>
            </ul>
          </el-form-item>
          <!-- <el-form-item label="发票确认:" prop="isConfirm" v-if="invBtnChange">
                    <el-select v-model="invPartner.isApproval" :disabled="disableInpt" placeholder="">
                        <el-option v-for="item in isConfirmOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item> -->

        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <div v-if="!invBtnChange">
          <el-button class="footer_button2" round @click="cancleApplyInvDialog()">取 消</el-button>
          <el-button class="footer_button1" type="primary" @click="submitInvForm(invPartner)">提 交</el-button>
        </div>
        <div v-else>
          <el-button class="footer_button1" type="primary" @click="applyInvDialog = false">关 闭</el-button>
        </div>
      </span>
    </el-dialog>

    <div v-show="detailDisplay">
      <!-- <div class="second_return_icon" @click="returnButton()">
                <i class="iconfont icon-fanhui" ></i>
                供应商详情
            </div> -->
      <Company ref="Company"></Company>
    </div>
  </div>
</template>
<script>
import { getClientList, getCustomerNum, editCustomer, getCoreInvoiceList, editCoreInvoice } from "@/api/coreEnterprise.js";
import { findCodeInfoByKey, uploads, getCompanyNameList, getAllCity } from "@/api/api.js";
import { mixins1 } from "@/mixins/index";
// import NoData from '@/components/noData'
import Company from '../../supplier/components/companyDetail.vue'
import Page from '@/components/page'
import formValidation from "@/utils/rule.js";
export default {
  mixins: [mixins1],

  components: { Page, Company },
  data() {
    const valiedateinvoiceNo = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入发票号码'))
      } else if ((val.length < 8) && (/[0-9]+$/.test(val)))
        return cb(new Error('请输入8位为数字的发票号'))
      return cb()
    }
    const valiedateinvoiceAmount = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入发票金额'))
      }
      else if (val === 0 || val === '0') {
        return cb(new Error('发票金额不能为0'))
      }
      return cb()
    }
    const valiedatePayAmount = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请填写付款金额'))
      }
      else if (val === 0 || val === '0') {
        return cb(new Error('付款金额不能为0'))
      }
      return cb()
    }
    const valiedateinvoiceDate = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入发票日期'))
      }
      return cb()
    }
    const valiedateinvoiceTerm = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入发票账期'))
      }
      return cb()
    }
    const valiedateinvoiceState = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入发票付款状态'))
      }
      return cb()
    }
    const valiedateresource1 = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请选择是否已收货'))
      }
      return cb()
    }
    const valiedateresource2 = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请选择是否已收发票'))
      }
      return cb()
    }
    const valiedateupload = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请上传发票'))
      }
      return cb()
    }
    return {
      refreshTwo: 0,
      refresh: 0,
      //搜索框
      clientSearchform: {
        coreEnterpriseId: '',
        purchaseName: '',
        age: '',
        pageNo: 1,
        pageSize: 8
      },
      currentPage: 1,
      // 我的客户列表
      //父表格
      clientTable: [],
      coreEnterpriseId: '',
      //父分页
      faTotalPage: 0,
      showClientPop: false,//显示客户弹框
      //添加,编辑详情表单
      addClientform: {
        coreEnterpriseId: '',
        coreEnterpriseName: '',
        cnCompany: '',
        contacts: '',
        phone: '',
        email: '',
        age: '',
        suppDesc: '',
        country: '',
        province: '',
        city: '',
      },
      addClientRule: {
        coreEnterpriseName: [{ required: true, message: '请输入客户名称', trigger: 'blur' }],
        contacts: [{ required: true, message: '请输入客户联系人', trigger: 'blur' }
        ],
        country: [{ required: true, message: '请选择国家', trigger: 'blur' }],
        // province:[{required: true, message: '请选择省',trigger: 'blur'  }],
        city: [{ required: true, message: '请选择市', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入客户电话', trigger: 'blur' },
          { validator: formValidation.validateMobile, trigger: "blur" }
        ],
        email: [{ required: true, message: '请输入客户邮箱', trigger: 'blur' },
        { validator: formValidation.validateEmail, trigger: "blur" }
        ],
        age: [{ required: true, message: '请选择合作年限', trigger: 'blur' }],
        // suppDesc: [{ required: true, message: '请输入合作信息描述', trigger: 'blur' }],
      },
      dialogClientTitle: '添加客户',
      disabledClient: false,
      cooperYearsOptions: [
        {
          value: '1',
          label: '新客户'
        }, {
          value: '2',
          label: '1-3年'
        }, {
          value: '3',
          label: '3年以上'
        }
      ],
      // 子表格搜索条件
      invSearchForm: {
        invoiceNo: "",
        invoiceDate: "",
        invoiceAmount: "",
        coreEnterpriseId: '',
        pageNo: 1,
        pageSize: 8
      },
      //子表格
      childTable: [],
      //子分页
      childTotalPage: 0,
      addInvoiceDialog: false,
      addInvMessageDialog: false,
      addInvSearch: {
        clientName: ''
      },
      purchaserList: [],
      //添加发票表单
      form: {
        coreEnterpriseId: '',
        invoiceNum: '',
        purchaseName: '',
        invoiceDate: '',
        invoiceState: '',
        purchaser1: '',
        invoiceNo: '',
        newInvoiceAmount: '',
        invoiceAmount: '',
        state: '',
        invoiceUrl: '',
        financingInvoiceState: '',
        isReceived: '0',
        isInvoice: '0',
        enterpriseId: '',
        supplierId: '',
        paymentDate: '',
        paymentAmount: '',
        newPaymentAmount: '',
        paymentVoucher: '',
        invoiceAccountDate: '',
      },
      rulesInvoice: {
        invoiceNo: [
          { required: true, validator: valiedateinvoiceNo, trigger: 'blur' }
        ],
        newInvoiceAmount: [
          { required: true, validator: valiedateinvoiceAmount, trigger: 'blur' }
        ],
        invoiceDate: [
          { required: true, validator: valiedateinvoiceDate, trigger: 'blur' }
        ],
        invoiceState: [
          { required: true, validator: valiedateinvoiceState, trigger: 'blur' }
        ],
        isReceived: [
          { required: true, validator: valiedateresource1, trigger: 'blur' }
        ],
        isInvoice: [
          { required: true, validator: valiedateresource2, trigger: 'blur' }
        ],
        invoiceUrl: [
          { required: true, validator: valiedateupload, trigger: 'blur' }
        ],
        invoiceAccountDate: [
          { required: true, validator: valiedateinvoiceDate, trigger: 'blur' }
        ],
        newPaymentAmount: [
          { required: true, validator: valiedatePayAmount, trigger: "blur" },
        ],
        paymentDate: [
          { required: true, message: "请选择付款日期", trigger: "blur" },
        ],
        paymentVoucher: [
          { required: true, message: "请上传图片", trigger: "blur" },
        ],
      },
      fileList: [],
      payFileList: [],
      payEditUrlList: [],
      // compressImg:require('@/assets/img/system/supplier/invoice.png'),
      // ulDisplay:true,
      addInvoiceMessageTitle: '添加发票信息',
      state: -1,
      //发票确认
      dialogInvTit: "发票确认",
      applyInvDialog: false,
      invPartner: {
        invoiceNo: "",
        invoiceAmount: "",
        invoiceDate: "",
        invoiceDateEnd: "",
        invoiceUrl: '',
        isConfirm: '',
        isApproval: '',
        isApprovalName: '',
        invoiceState: '',
        invoiceStateName: '',
        invoiceAccountDate: '',
        paymentVoucher: '',
        paymentAmount: '',
        paymentDate: '',
        isReceived: '',
        isInvoice: '',
      },
      invRules: {
        invoiceState: [
          { required: true, message: "请选择发票付款状态", trigger: "blur" },
        ],
        paymentAmount: [
          { required: true, validator: valiedatePayAmount, trigger: "blur" },
        ],
        invoiceAmount:[
        { required: true, validator: valiedateinvoiceAmount, trigger: "blur" },
        ],
        paymentDate: [
          { required: true, message: "请选择付款日期", trigger: "blur" },
        ],
        paymentVoucher: [
          { required: true, message: "请上传图片", trigger: "blur" },
        ],
      },
      invoiceUrlList: [],
      payUrlList: [],
      isConfirmOptions: [
        {
          value: "1",
          label: "认可",
        },
        {
          value: "0",
          label: "未认可",
        },
      ],
      invBtnChange: false, //false:发票详情页,true:发票确认页
      disableInvInpt: true,
      rowSelected: '',
      refPage: 0,//key
      detailDisplay: false,
      clientDiv: true,
      dataname: [],
      addressList0: [],
      addressList1: [],
      addressList2: [],
      levels: 1,//地区层级
      pid: 1,//地区层级
      addPayState: false,
      editPayState: true,
      invoiceAImg: require('@/assets/img/system/invoice_A.png'),
      invoiceBImg: require('@/assets/img/system/invoice_B.png'),
      invoiceCImg: require('@/assets/img/system/invoice_C.png'),
      invoiceDImg: require('@/assets/img/system/invoice_D.png'),
      invoiceNaImg: require('@/assets/img/system/invoice_NA.png'),
      invoiceGradeDialog: false,
      invoiceImg: ''
    }
  },
  created() {
    this.tableWidth()
    this.resizeFn();
    this.getClientList()
    this.corOptionsList()
  },
  methods: {
    // 表格展开或关闭时,清空检索条件
    onExpandChange(){
      this.$refs.invSearchForm.resetFields()
      this.invSearchForm.invoiceDate = ''
      this.invSearchForm.pageNo = 1
    },
    searchMoney(val) {
      val = val.replace(/[^\d]/g, '')
      this.invSearchForm.invoiceAmount = val
    },
    searchNum(val) {
      val = val.replace(/[^\d]/g, '')
      this.invSearchForm.invoiceNo = val
    },
    searchInput1(val) {
      val = val.replace(/[^\d]/g, '')
      this.clientSearchform.coreEnterpriseId = val
    },
    // 发票评级提示
    clickInvoiceGrade(val) {
      this.invoiceGradeDialog = true
      if (val == 'A') {
        this.invoiceImg = 1
      } else if (val == 'B') {
        this.invoiceImg = 2
      } else if (val == 'C') {
        this.invoiceImg = 3
      } else if (val == 'D') {
        this.invoiceImg = 4
      } else {
        this.invoiceImg = 5
      }
    },
    regNumber1(val) {
      val = val.replace(/[^\d]/g, '')
      this.invPartner.paymentAmount = val
    },
    //上传发票
    handleUploadFile(params) {
      if (this.form.invoiceUrl == '' || this.form.invoiceUrl == null) {
        const file = params.file
        let form = new FormData();
        form.append("files", file);
        uploads(form).then(res => {
          this.form.invoiceUrl = res[0]
        })
      }
    },
    handleRemove() {
      this.form.invoiceUrl = ''
      this.fileList = []
    },
    //上传付款
    payUploadFile(params) {
      if (this.form.paymentVoucher == '' || this.form.paymentVoucher == null) {
        const file = params.file
        let form = new FormData();
        form.append("files", file);
        uploads(form).then(res => {
          this.form.paymentVoucher = res[0]
        })
      }
    },
    payFileRemove() {
      this.form.paymentVoucher = ''
      this.payFileList = []
    },
    //编辑上传付款
    payEditUploadFile(params) {
      if (this.invPartner.paymentVoucher == '' || this.invPartner.paymentVoucher == null) {
        const file = params.file
        let form = new FormData();
        form.append("files", file);
        uploads(form).then(res => {
          this.invPartner.paymentVoucher = res[0]
          this.$refs.invPartner.validateField("paymentVoucher")
        })
      }
    },
    payEditFileRemove() {
      this.invPartner.paymentVoucher = ''
      this.payEditUrlList = []
    },
    //金额过滤
    inputMoneyFocus(value) {
      this.form.invoiceAmount = this.filterOut(value)
    },
    inputMoneyBlur(value) {
      this.form.invoiceAmount = value
      this.form.newInvoiceAmount = this.filter(value)
    },
    //付款金额过滤
    inputPayFocus(value) {
      this.form.paymentAmount = this.filterOut(value)
    },
    inputPayBlur(value) {
      this.form.paymentAmount = value
      this.form.newPaymentAmount = this.filter(value)
    },
    //编辑付款金额过滤
    inputEditPayFocus(value) {
      console.log(value)
      this.invPartner.paymentAmount = this.filterOut(value)
    },
    inputEditPayBlur(value) {
      console.log(value)
      this.invPartner.paymentAmount = this.filter(value)
    },
    //改变发票付款状态
    changeInvState() {
      this.refresh++
      const state = this.form.invoiceState

      if (state == 1) {
        this.addPayState = true
      }
      else {
        this.addPayState = false
        this.form.paymentDate = ''
        this.form.paymentAmount = ''
        this.form.paymentVoucher = ''
      }
    },
    changeEditInvState() {
      this.refreshTwo++
      const state = this.invPartner.invoiceState
      if (state == 1) {
        this.editPayState = false
      }
      else {
        this.editPayState = true
        this.invPartner.paymentDate = ''
        this.invPartner.paymentAmount = ''
        this.invPartner.paymentVoucher = ''
      }
    },
    //获取客户列表
    async getClientList() {
      const res = await getClientList('pageNo=' + this.clientSearchform.pageNo + '&pageSize=' + this.clientSearchform.pageSize
        + '&purchaseName=' + this.clientSearchform.purchaseName + '&age=' + this.clientSearchform.age + '&coreEnterpriseId=' + this.clientSearchform.coreEnterpriseId)
      if (res && res.code == 200) {
        let obj = res.data
        this.clientTable = obj.records
        this.faTotalPage = obj.total
      }
    },
    searchForm() {
      this.page.pageNo = 1
      this.getClientList()
    },
    //清空搜索条件
    clearCliSearch() {
      this.clientSearchform.coreEnterpriseId = ''
      this.clientSearchform.purchaseName = ''
      this.clientSearchform.age = ''
      this.clientSearchform.pageNo = 1
      this.getClientList()
    },
    //清空发票搜索条件
    clearInvSearch() {
      // this.invSearchForm.invoiceNo = ''
      // this.invSearchForm.invoiceDate = ''
      // this.invSearchForm.invoiceAmount = ''
      this.$refs.invSearchForm.resetFields()
      this.invSearchForm.invoiceDate = ''
      this.invSearchForm.pageNo = 1
      this.getInvList()
    },
    //编辑客户
    editClientDialog(row) {
      this.showClientPop = true
      this.disabledClient = false
      this.$nextTick(() => {
        this.addClientform = JSON.parse(JSON.stringify(row))
        this.getCuntryCity().then(() => {
          this.changeAddress0(this.addClientform.country).then(() => {
            this.changeAddress1(this.addClientform.province)
          })
        })
      })
    },
    //获取客户编号
    async addClientDialog() {
      this.showClientPop = true
      this.disabledClient = false
      // const res = await getCustomerNum()
      this.$nextTick(() => {
        this.$refs.addClientform.resetFields()
        delete this.addClientform.id
        this.getClientList()
        // if (res && res.code == 200) {
        // let obj=res.data
        // this.addClientform.coreEnterpriseId=obj
        // this.getClientList()
        // }
      })
      this.getCuntryCity()
    },
    // 供应商名称模糊搜索
    handleSelect(item) {
      this.addClientform.coreEnterpriseName = item.company_name
      // this.addClientform.purchaseName = item.company_name
      // this.addClientform.coreEnterpriseId = item.userCode
    },
    //获取供应商名称
    async getCompanyNameList() {
      if (this.addClientform.coreEnterpriseName) {
        let data = await getCompanyNameList('comName=' + this.addClientform.coreEnterpriseName)
        this.dataname = data.data
        if (this.dataname) {
          this.dataname.forEach((item, index) => {
            this.dataname[index].value = item.company_name
          });
        }

      }
      else {
        this.dataname = []
      }
    },
    querySearch(queryString, cb) {
      this.getCompanyNameList().then(() => {
        var restaurants = this.dataname;
        var results = []
        if (restaurants) {
          results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
          // results = queryString ? restaurants : [];
        }
        cb(results);
      })
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase())!==-1);
      };
    },
    async changeClient(item) {
      if (item) {
        //   // const res = await getCustomerNum()
        //   // this.addClientform.coreEnterpriseId= res.data
        this.addClientform.purchaseName = item
      }

    },
    //提交添加客户
    submitClientForm(addClientform) {
      this.$refs[addClientform].validate((valid) => {
        if (valid) {
          this.editClient()
        } else {
          return false;
        }
      });
    },

    //编辑添加客户
    async editClient() {
      let res = await editCustomer(this.addClientform)
      if (res && res.code == 200) {
        this.getClientList()
        this.showClientPop = false
      }
      else {
        this.$message.error(res.msg)
        delete this.addClientform.id
      }
    },

    //父分页方法
    onPageChange(event) {
      this.clientSearchform.pageNo = event
      this.clientSearchform.pageSize = 8
      this.getClientList()
    },
    //子分页方法
    onChildPageChange(event) {
      this.invSearchForm.pageNo = event
      this.invSearchForm.pageSize = 8
      this.getInvList()
    },
    //取消添加客户
    cancelAddClientForm() {
      this.$refs.addClientform.resetFields()
      this.showClientPop = false
    },

    resizeFn() {
      if (document.body.clientWidth > 1664) {
        this.width30 = 30
        this.lastWidth = 130
        this.comapnyWidth = 220
        this.width175 = 175
      } else {
        this.width30 = 20
        this.lastWidth = 110
        this.comapnyWidth = 145
        this.width175 = 135
      }
    },

    //展开当前关闭其他展开表格
    onClickExpansion(row) {
      this.rowSelected = row
      this.coreEnterpriseId = row.coreEnterpriseId
      this.clientTable.map((item) => {
        if (row.id != item.id) {
          item.isExpand = false;
          this.$refs.treeTable.toggleRowExpansion(item, false);
        }
      });
      row.isExpand = !row.isExpand;
      this.$refs.treeTable.toggleRowExpansion(row);
      if (row.isExpand) {
        this.childTable = []
        this.getInvList()
      }
    },
    //查询发票列表
    async getInvList() {
      if (this.invSearchForm.invoiceDate) {
        this.startDate = this.invSearchForm.invoiceDate.slice(',')[0]
        this.endDate = this.invSearchForm.invoiceDate.slice(',')[1]
      } else {
        this.startDate = ''
        this.endDate = ''
      }
      const res = await getCoreInvoiceList('pageNo=' + this.invSearchForm.pageNo + '&pageSize=' + this.invSearchForm.pageSize + '&startDate='
        + '&coreEnterpriseId=' + this.coreEnterpriseId + '&invoiceAmount=' + this.invSearchForm.invoiceAmount + '&invoiceDate='
        + this.startDate + '&endDate=' + this.endDate + '&invoiceNo=' + this.invSearchForm.invoiceNo)
      if (res && res.code == 200) {
        let obj = res.data
        this.childTable = obj.records
        this.childTotalPage = obj.total
      }
    },
    // 添加发票
    addInvoice() {
      this.state = -1
      this.addInvoiceDialog = true
      // this.ulDisplay = false
      this.fileList = []
      this.payFileList = []
      this.form.invoiceUrl = ''
      this.form.paymentVoucher = ''
      if (this.$refs.form) {
        this.$nextTick(() =>
          this.$refs.form.resetFields()
        )
      }
      this.getAddClientList()
      delete this.form.id
    },
    //获取客户名称列表
    async getAddClientList() {
      const res = await getClientList('&pageNo=-1&pageSize=10' + '&purchaseName=' + this.addInvSearch.clientName)
      if (res && res.code == 200) {
        let obj = res.data
        this.purchaserList = obj
      }
    },
    cancleApplyInvDialog() {
      this.applyInvDialog = false
      this.$refs.invPartner.resetFields()
    },
    //提交确认发票
    async submitInvForm(invPartner) {
      this.invPartner.invoiceAmount = this.filterOut(this.invPartner.invoiceAmount)
      this.invPartner.paymentAmount == null || this.invPartner.paymentAmount == 0 ? '' : this.invPartner.paymentAmount = this.filterOut(this.invPartner.paymentAmount)
      this.$refs.invPartner.validate(async (valid) => {
        if (valid) {
          let res = await editCoreInvoice(this.invPartner)
          if (res && res.code == 200) {
            this.editPayState = false
            this.applyInvDialog = false
            this.getInvList()
          }
          else {
            this.$message.error(res.msg);
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 列表点击样式
    changeStyle(index, item) {
      this.form = {
        coreEnterpriseId: '',
        invoiceNum: '',
        purchaseName: '',
        invoiceDate: '',
        invoiceState: '',
        purchaser1: '',
        invoiceNo: '',
        newInvoiceAmount: '',
        invoiceAmount: '',
        state: '',
        invoiceUrl: '',
        financingInvoiceState: '',
        isReceived: '0',
        isInvoice: '0',
        enterpriseId: '',
        supplierId: '',
        paymentDate: '',
        paymentAmount: '',
        newPaymentAmount: '',
        paymentVoucher: '',
        invoiceAccountDate: '',
      }
      this.state = index
      console.log(item.coreEnterpriseName)
      this.form.purchaseName = item.coreEnterpriseName
      console.log(this.form.purchaseName)
      this.form.enterpriseId = item.coreEnterpriseId
    },
    // 下一步
    next() {
      if (this.state != -1) {
        // this.ulDisplay = false
        this.addInvoiceDialog = false
        this.addInvMessageDialog = true
        this.addInvSearch.clientName = '';
        console.log(this.form.purchaseName)
        // this.$refs.form.resetFields()
        delete this.form.id
      }
    },

    cancel() {
      this.addInvMessageDialog = false
      this.addInvoiceDialog = false
    },
    // 弹框关闭按钮
    handleClose() {
      this.addInvoiceDialog = false
      this.addInvMessageDialog = false
    },
    //发票详情
    dialogInvDetail(item) {
      this.dialogInvTit = "发票详情";
      this.invBtnChange = false;
      this.disableInvInpt = false
      this.invPartner = item;
      // this.invPartner.isApprovalName=this.invPartner.isApproval==0?"未认可":'认可'
      this.invPartner.invoiceStateName = this.invPartner.invoiceState == '0' ? "未付款" : '已付款'
      this.invPartner.invoiceAmount = this.filter(this.invPartner.invoiceAmount)
      this.invPartner.paymentAmount = this.filter(this.invPartner.paymentAmount)
      this.invoiceUrlList = []
      this.payUrlList = []
      if (this.invPartner.invoiceUrl) {
        let obj = {}
        obj.imgUrl = this.invPartner.invoiceUrl
        this.invoiceUrlList.push(obj)
      }
      if (this.invPartner.paymentVoucher) {
        let obj = {}
        obj.imgUrl = this.invPartner.paymentVoucher
        this.payUrlList.push(obj)
      }

      this.applyInvDialog = true;
    },
    //编辑发票
    editInvDetail(item) {
      this.dialogInvTit = "编辑发票";
      this.editPayState = true;
      this.disableInvInpt = true
      this.invPartner = JSON.parse(JSON.stringify(item))
      this.invPartner.invoiceStateName = this.invPartner.invoiceState == '0' ? "未付款" : '已付款'
      this.invPartner.invoiceAmount = this.filter(this.invPartner.invoiceAmount)
      this.invPartner.paymentAmount = this.filter(this.invPartner.paymentAmount)
      this.invoiceUrlList = []
      this.payEditUrlList = []
      if (this.invPartner.invoiceUrl) {
        let obj = {}
        obj.imgUrl = this.invPartner.invoiceUrl
        this.invoiceUrlList.push(obj)
      }
      if (this.invPartner.paymentVoucher) {
        let obj = {}
        obj.imgUrl = this.invPartner.paymentVoucher
        this.payEditUrlList.push(obj)
      }

      this.applyInvDialog = true;
    },
    //提交新增发票
    async submitNewInvForm(form) {
      this.form.invoiceAmount = this.filterOut(this.form.invoiceAmount)
      this.form.paymentAmount == null || this.form.paymentAmount == 0 ? '' : this.form.paymentAmount = this.filterOut(this.form.paymentAmount)
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let res = await editCoreInvoice(this.form)
          if (res && res.code == 200) {
            this.addInvMessageDialog = false
            this.invSearchForm.pageNo = 1
            this.currentPage = 1
            this.getInvList().then(() => {
              this.refPage++
              if (this.rowSelected.invoiceQty) {
                this.rowSelected.invoiceQty = this.childTotalPage
              } else {
                this.getClientList()
              }
            })
          }
          else {
            this.$message.error('您的发票已添加过');
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //字段合作年限
    async corOptionsList() {
      let res = await findCodeInfoByKey('ms=7')
      this.cooperYearsOptions = res.data
      let res2 = await findCodeInfoByKey('ms=3')
      this.isConfirmOptions = res2.data
    },

    detailIndex(row) {
      this.$refs.Company.getCompany(row.coreEnterpriseName)
      this.clientDiv = false
      this.detailDisplay = true
    },
    // 返回上一页
    returnButton() {
      this.detailDisplay = false
      this.clientDiv = true
      window.scrollTo(0, 0)
    },
    //国家
    async getCuntryCity() {
      let res = await getAllCity('levels=' + this.levels + '&pid=' + this.pid)
      if (res && res.code == 200) {
        let list = res.data
        this.addressList0 = list
      }
    },
    //国家
    async changeAddress0(val) {
      if (val) {
        this.$refs.addClientform.validateField("country")
        // this.addClientform.province=''
        // this.addClientform.city=''
        // this.addressList2 = []
        let sec = {}
        sec = this.addressList0.filter(item => item.name == val)[0]
        let res = await getAllCity('levels=2' + '&pid=' + sec.sid)//
        if (res && res.code == 200) {
          let list = res.data
          this.addressList1 = list
          if (list.length < 1) {
            this.accForm.province = ''
            this.accForm.city = ''
            let res2 = await getAllCity('levels=3' + '&pid=' + sec.sid)
            if (res2 && res2.code == 200) {
              let list2 = res2.data
              this.addressList2 = list2
            }
          }
        }
      } else {
        this.addressList1 = []
        this.addressList2 = []
      }
    },
    //省市联动
    async changeAddress1(val) {
      if (val) {
        this.$refs.addClientform.validateField("province")
        // this.addClientform.city=''
        let thr = {}
        thr = this.addressList1.filter(item => item.name == val)[0]
        let res = await getAllCity('levels=3' + '&pid=' + thr.sid)
        if (res && res.code == 200) {
          let list = res.data
          this.addressList2 = list
        }
      } else {
        this.addressList2 = []
      }
    },

    //下拉框校验
    changeAddress2(val) {
      if (val) {
        this.$refs.addClientform.validateField("city")
      }
    },
  }
}
</script>
<style scoped>
.search_flex_box>>>.el-form-item:last-child {
  margin-bottom: 0;
}

.search_flex_box>>>.el-form-item:nth-child(3) {
  margin-right: 0;
}

.search_flex_box>>>.el-date-editor--daterange.el-input__inner {
  width: 300px;
}

.search_flex_box>>>.el-date-editor .el-range-separator {
  display: none;
}

.search_flex_box>>>.el-select .el-input.is-focus .el-input.is-active .el-input__inner {
  border: 1px solid #ffffff;
}

.search_condition {
  flex: 1;
}

.table_box_top_title {
  flex: 1;
}

.search_invoice {
  margin-bottom: 20px;
}

.add_invoice_messages>>>.el-form-item__label {
  min-width: 98px;
  text-align: left;
}

.add_invoice_messages>>>.el-form-item__content {
  float: left;
}

.add_invoice_messages>>>.el-input__prefix {
  left: 92.5%;
}

.add_client_dialog /deep/ .el-textarea__inner {
  height: 80px !important;
}

.add_client_dialog>>>.el-select.select_class,
.add_client_dialog>>>.el-date-editor.el-input {
  width: 100%;
  height: 100%;
}

.add_client_dialog>>>.el-input__prefix {
  position: absolute;
  left: auto;
  right: 0;
}

.add_invoice_message .is-success .el-date-editor--date:hover>>>.el-input__prefix {
  display: none;
}

.sub_search_condition {
  background: #edf2f5;
  border-radius: 10px;
  padding: 17px 20px;
  flex: 1;
}

.search_condition>>>.el-date-editor--daterange.el-input__inner,
.sub_search_condition>>>.el-input {
  width: 280px;
}

.search_condition>>>.el-form-item:last-child {
  margin-right: 0;
}

.noLink {
  color: #606266;
  text-decoration: none;
  width: 100%;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
}

.choose_active_change {
  color: #ffffff;
  background: #339c9b;
}

.add_invoice_message>>>.el-input__prefix {
  position: absolute;
  left: 286px;
}

.change_width>>>.el-form-item__label {
  width: 173px;
  min-width: 128px;
}

.change_padding .is-success .el-date-editor--date:hover>>>.el-input__prefix {
  display: none;
}

.change_width>>>.el-radio-group {
  margin-top: 8px;
}

.dialog_width500 .change_width>>>.el-form-item__content {
  line-height: 40px;
}

.change_flex>>>.el-form-item__content {
  float: none;
  display: flex;
  align-items: flex-end;
  font-size: 12px;
  color: #92a2bc;
}

.change_flex span {
  height: 26px;
}

.add_class>>>.el-form-item__label {
  min-width: 140px;
}

.add_class>>>.el-form-item__content {
  width: 336px;
}

.add_class>>>.el-date-editor.el-input {
  width: 336px;
}

.add_class>>>.el-input__prefix {
  left: 306px;
}

.add_bottom {
  margin-bottom: 10px;
}

.upload_box {
  width: 60px;
  height: 60px;
  margin-right: 6px;
  background: #f7fafc;
  border-radius: 4px;
  border: 1px solid #e1e7ea;
}

.upload_box i {
  font-size: 20px;
  line-height: 60px;
  color: #7c969b;
}

.upload_box i:focus {
  color: #7c969b;
}

.invimg_list li {
  float: left;
  width: 60px;
  height: 60px;
  margin-right: 12px;
  margin-bottom: 5px;
  background: #f7fafc;
  border-radius: 4px;
  border: 1px solid #e1e7ea;
}

.choose_active {
  color: #162747;
  background: #ffffff;
}

.no_border>>>.el-input__inner {
  border: none;
  background: #ffffff;
  color: #162747;
  padding-left: 0;
}

.upload_demo>>>.el-upload-list {
  margin-top: 0;
  width: auto;
}

.upload_demo>>>.el-upload__tip {
  right: 90px;
}

.add_invoice_messages>>>.two_select {
  margin-bottom: 0;
}

.add_invoice_messages>>>.two_select .el-select {
  width: 184px;
}

.add_invoice_messages>>>.two_select:first-child .el-select {
  margin-right: 10px;
}

.flex>>>.el-form-item__content {
  display: flex;
}

.add>>>.hInpt {
  display: none;
}

@media screen and (max-width: 1664px) {
  .search_condition .el-input {
    width: 200px;
    height: 20px;
  }

  .search_condition>>>.el-form-item {
    margin-right: 20px;
  }

  .add_invoice_messages>>>.el-form-item__label {
    min-width: 83px;
    text-align: left;
  }

  .add_invoice_messages>>>.el-input__prefix {
    left: 87%;
  }

  .upload_box {
    width: 40px;
    height: 40px;
    margin-right: 2px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }

  .upload_box i {
    font-size: 14px;
    line-height: 40px;
    color: #7c969b;
  }

  .invimg_list li {
    width: 40px;
    height: 40px;
    margin-right: 8px;
    margin-bottom: 3px;
    border-radius: 2px;
  }

  .add_invoice_messages>>>.el-input__prefix {
    left: 91%;
  }

  .add_invoice_messages>>>.two_select .el-select {
    width: 112px;
  }

  .change_width>>>.el-form-item__label {
    width: 114px;
    min-width: 108px;
  }

  .add_invoice_messages>>>.two_select:first-child .el-select {
    margin-right: 6px;
  }

  .add_invoice_message .change_width>>>.el-form-item__content {
    line-height: 30px;
  }

  .add_invoice_message>>>.el-input__prefix {
    left: 165px;
  }

  .upload_demo>>>.el-upload__tip {
    right: 8px;
  }
}</style>